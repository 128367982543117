import React from 'react';
import './App.css';
import Model from './Model';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a className="logo"><img src="daycare.png" alt="Logo" /></a>
      </header>
      <div className="thumbnails">
        <a href="https://x.com/doggy_onsol"><img src="kamali.png" alt="thumbnail1" /></a>
        <a href="http://t.me/doggystylecto"><img src="dogay.png" alt="thumbnail2" /></a>
        <a href="https://dexscreener.com/solana/fxjd6v9awrw635n69rtgqk9qj5ee7gxtsp2ssm3xg749"><img src="kamali.png" alt="thumbnail3" /></a>
        <a href="https://pump.fun/DwcszRJF8fVNPcs2kUXfW7QJKGNUjn7Yvemhxnx73awS"><img src="dogay.png" alt="thumbnail5" /></a>
      </div>
      <div className="container">
        <div className="model-container-wrapper">
          <div className="model-container">
            <Model />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
